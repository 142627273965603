import React, { useEffect } from "react"
import { graphql } from "gatsby"
import MetaData from "../components/meta"
import Layout from "../components/Layout"

// Import styles
import "../styles/submit-my-contribution.scss"

// Images
import illustration from "../images/submit-my-contribution/illustration.svg"

const SubmitMyContributionPage = ({ data, location }) => {
  useEffect(() => {
    // Load Hubspot forms in modals
    let script = document.createElement("script")
    script.src = "//js.hsforms.net/forms/v2.js"
    script.onload = function () {
      hbspt.forms.create({
        portalId: "5814022",
        formId: "74bff6cc-df7a-4bcf-a50b-bbb42959d521",
        target: "#submit-my-contribution-form",
      })
    }
    document.getElementsByTagName("head")[0].appendChild(script)
  }, [])

  return (
    <Layout>
      <MetaData
        data={{
          ghostPage: data.current_page,
        }}
        location={location}
      />
      <div className="landing-page landing-page--submit-my-contribution">
        <div className="submit-my-contribution-hero">
          <div className="wrapper-1200">
            <img src={illustration} alt="Get Your Traefik Labs Swag!" />
            <h1>Get Your Traefik Labs Swag!</h1>
            <p>
              Thank you for contributing to the Traefik Community through your
              code, content, and community efforts! We’d like to send you some
              Traefik Labs swag. Please fill out the form below and we’ll send
              your gift soon!
            </p>
          </div>
        </div>

        <div className="submit-my-contribution-form">
          <div className="wrapper-1200">
            <div
              id="submit-my-contribution-form"
              className="hubspot-form-wrapper"
            >
              {/* Spinner */}
              <div className="spinner active">
                <div className="lds-ripple">
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SubmitMyContributionPage

export const query = graphql`
  query {
    current_page: ghostPage(slug: { eq: "submit-my-contribution" }) {
      ...PageMetaFields
    }
  }
`
